// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import logo1 from '../pictures/logo1.png';
import AuthService from "../services/auth.service";
import { FaBars, FaTimes } from "react-icons/fa";
import OptimizedImage from './OptimizedImage';

const Header = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
      setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    }
  }, []);

  const logOut = () => {
    AuthService.logout();
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <nav className="bg-[#393E46] px-6 py-3 md:px-24 md:flex md:justify-between" aria-label="Main navigation">
        <div className="flex justify-between items-center">
          <Link to="/" className="navbar-brand" aria-label="Home">
            <OptimizedImage src={logo1} alt="Secured logo" className="max-w-10" />
          </Link>
          
          <button 
            className="md:hidden text-white"
            onClick={toggleMenu}
            aria-expanded={menuOpen}
            aria-controls="main-menu"
          >
            <span className="sr-only">{menuOpen ? "Close menu" : "Open menu"}</span>
            {menuOpen ? <FaTimes size={30} aria-hidden="true" /> : <FaBars size={30} aria-hidden="true" />}
          </button>
        </div>

        <ul id="main-menu" className={`flex-col md:flex md:flex-row gap-3 text-white items-center ${menuOpen ? 'flex' : 'hidden'} md:flex`}>
          {!currentUser && (
            <li>
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
          )}

          {showModeratorBoard && (
            <li>
              <Link to="/mod" className="nav-link">
                Moderator Board
              </Link>
            </li>
          )}

          {showAdminBoard && (
            <li>
              <Link to="/admin" className="nav-link">
                Admin-Center
              </Link>
            </li>
          )}

          {currentUser && (
            <li>
              <Link to="/user" className="nav-link">
                File-Management
              </Link>
            </li>
          )}

          {currentUser ? (
            <>
              <li>
                <Link to="/profile" className="nav-link">
                  {currentUser.username}
                </Link>
              </li>
              <li>
                <a href="/login" className="nav-link" onClick={logOut}>
                  Logout
                </a>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="/login" className="nav-link">
                  Login
                </Link>
              </li>
              <li>
                <Link to="/register" className="nav-link">
                  Sign Up
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
