 // auth.service.js
// import axios from "axios";

import axios from '../axiosConfig';

const API_URL = process.env.REACT_APP_API_URL + "/auth/";

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = (username, password, rememberMe) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
      rememberMe
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const verifyTwoFactor = (userId, twoFactorToken) => {
  return axios.post(API_URL + "verify2fa", { userId, twoFactorToken });
};

const get2FASecret = () => {
  return axios.get(API_URL + "2fa/secret", { headers: authHeader() });
};

const enable2FA = () => {
  return axios.post(API_URL + "2fa/enable", {}, { headers: authHeader() });
};

const disable2FA = () => {
  return axios.post(API_URL + "2fa/disable", {}, { headers: authHeader() });
};

const authHeader = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.accessToken) {
    return { Authorization: 'Bearer ' + user.accessToken };
  } else {
    return {};
  }
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  verifyTwoFactor,
  get2FASecret,
  enable2FA,
  disable2FA,
  authHeader
};

export default AuthService;
