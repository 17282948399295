import axios from 'axios';

axios.defaults.withCredentials = true;

export default axios;

////////////////////////////////////////////////// below process for csrf protection.
// // added for CSRF protection 
// // src/axiosConfig.js
// import axios from 'axios';

// axios.defaults.withCredentials = true;

// axios.interceptors.request.use((config) => {
//   const cookies = document.cookie.split('; ');
//   const csrfCookie = cookies.find(cookie => cookie.startsWith('XSRF-TOKEN='));
//   if (csrfCookie) {
//     const token = csrfCookie.split('=')[1];
//     config.headers['X-XSRF-TOKEN'] = token;
//   }
//   return config;
// }, (error) => {
//   return Promise.reject(error);
// });

// export default axios;



