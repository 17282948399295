import React from 'react'
import { TailSpin } from 'react-loader-spinner';

const Loading = ({width}) => {
  return (
   <div className='text-center flex justify-center'>
   <TailSpin
   visible={true}
   width={width}
   color="#007bff"
   ariaLabel="tail-spin-loading"
   radius="1"
   wrapperStyle={{}}
   wrapperClass=""
 />
   </div>
  )
}

export default Loading