// src/App.js
import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Loading from "./components/Loading";

const Login = lazy(() => import("./components/Login"));
const Register = lazy(() => import("./components/Register"));
const Home = lazy(() => import("./components/Home"));
const Profile = lazy(() => import("./components/Profile"));
const BoardUser = lazy(() => import("./components/BoardUser"));
const BoardModerator = lazy(() => import("./components/BoardModerator"));
const BoardAdmin = lazy(() => import("./components/BoardAdmin"));

const App = () => {
  return (
    <div className="font-Poppins min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow">
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/user" element={<BoardUser />} />
            <Route path="/mod" element={<BoardModerator />} />
            <Route path="/admin" element={<BoardAdmin />} />
          </Routes>
        </Suspense>
      </main>
    </div>
  );
};

export default App;
